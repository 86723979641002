/* =================== DETALHE PRODUTO =================== */
jQuery(document).ready(function ($) {
  // Carrosel fotos
  const dots =
    $(".produto-det-foto").length > 1 && $(".produto-det-foto").length <= 10;
  $(".produto-detalhe-fotos-destaque").slick({
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    dots: dots,
    arrows: true,
    fade: true,
    asNavFor: ".produto-detalhe-fotos-thumbs",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  });

  // Carrosel thumbs
  $(".produto-detalhe-fotos-thumbs").slick({
    slidesToShow: 4,
    infinite: false,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: ".produto-detalhe-fotos-destaque",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });
});
