/* =================== BLOCOS HOME =================== */
$(function () {
  // adiciona classe carrosel no mobile
  // $(window).resize(function () {
  if ($(window).width() < 760) {
    $("#blocosHome").addClass("carrosel carrosel-white carrosel-blocos");
    $("#blocosHome").removeClass("blocos-wrp");
  } else {
    $("#blocosHome").removeClass("carrosel carrosel-white carrosel-blocos");
    $("#blocosHome").addClass("blocos-wrp");
  }
  // });

  $(".carrosel-blocos").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  });

  // Cancela o evento de click nos blocos sem link
  $('.bloco-item[href="#"]').on("click", function (ev) {
    ev.preventDefault();
  });
});
