// Validation errors messages for Parsley
// Load this after Parsley
Parsley.addMessages("pt-br", {
  defaultMessage: Traduz("Este valor parece ser inválido."),
  type: {
    email: Traduz("Este campo deve ser um email válido."),
    url: Traduz("Este campo deve ser uma URL válida."),
    number: Traduz("Este campo deve ser um número válido."),
    integer: Traduz("Este campo deve ser um inteiro válido."),
    digits: Traduz("Este campo deve conter apenas dígitos."),
    alphanum: Traduz("Este campo deve ser alfanumérico."),
  },
  notblank: Traduz("Este campo não pode ficar vazio."),
  required: Traduz("Este campo é obrigatório."),
  pattern: Traduz("Este campo parece estar inválido."),
  min: Traduz("Este campo deve ser maior ou igual a %s."),
  max: Traduz("Este campo deve ser menor ou igual a %s."),
  range: Traduz("Este campo deve estar entre %s e %s."),
  minlength: Traduz(
    "Este campo é pequeno demais. Ele deveria ter %s caracteres ou mais."
  ),
  maxlength: Traduz(
    "Este campo é grande demais. Ele deveria ter %s caracteres ou menos."
  ),
  length: Traduz(
    "O tamanho deste campo é inválido. Ele deveria ter entre %s e %s caracteres."
  ),
  mincheck: Traduz("Você deve escolher pelo menos %s opções."),
  maxcheck: Traduz("Você deve escolher %s opções ou mais"),
  check: Traduz("Você deve escolher entre %s e %s opções."),
  equalto: Traduz("Este valor deveria ser igual."),
});

Parsley.setLocale("pt-br");
